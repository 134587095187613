import { ApolloLink } from '@apollo/client';
import { MAGENTO_CACHE_ID_HEADER } from '../constants';
import { DefaultContext } from '@apollo/client/core';

const magentoGqlCacheSetterLink = (magentoCacheIdFromCookie: string | undefined) =>
    new ApolloLink((operation, forward) => {
        // Attach the cache header to each outgoing request.
        operation.setContext((previousContext: DefaultContext) => {
            const { headers } = previousContext;
            if (magentoCacheIdFromCookie) {
                const withCacheHeader = {
                    ...headers,
                    [MAGENTO_CACHE_ID_HEADER]: magentoCacheIdFromCookie,
                };

                return {
                    ...previousContext,
                    headers: withCacheHeader,
                };
            } else {
                return previousContext;
            }
        });

        return forward(operation);
    });

export default magentoGqlCacheSetterLink;
