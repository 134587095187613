'use client';

import React from 'react';
import Toast from './toast';
import { useToastContext } from '@/lib/context/toasts/useToastContext';
import { IToastContext } from '@/lib/context/toasts/types';
import defaultClasses from './toastContainer.module.scss';

/**
 * A container for toast notifications.
 *
 * This component must be a child, nested or otherwise, of a
 * ToastContextProvider component.
 *
 * @typedef ToastContainer
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays toast notification data.
 */
const ToastContainer = () => {
    const { toasts }: { toasts: Map<number, IToastContext> } = useToastContext();

    // Given a map of toasts each with a property "timestamp", sort and display
    // based on the timestamp in reverse order (newer toasts appear on top).
    const sortByTimestamp = ([, toastA]: [any, IToastContext], [, toastB]: [any, IToastContext]) =>
        toastB.timestamp - toastA.timestamp;

    const toastElements = Array.from(toasts)
        .sort(sortByTimestamp)
        .map(([id, toast]) => {
            const key = toast.isDuplicate ? Math.random() : id;

            return <Toast key={key} {...toast} />;
        });

    return (
        <div id="toast-root" className={defaultClasses.root}>
            {toastElements}
        </div>
    );
};

export default ToastContainer;
