import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let optimizely: any;
}

export const useActivateOptimizely = ({
    isOptimizelyEnabled,
    optimizelyJsKey,
}: {
    isOptimizelyEnabled: boolean;
    optimizelyJsKey: string | null;
}) => {
    useEffect(() => {
        if (!isOptimizelyEnabled || !optimizelyJsKey || process.env.NEXT_PUBLIC_OPTIMIZELY_ACTIVATE_MANUAL !== '1') {
            return;
        }

        globalThis.optimizely = globalThis.optimizely || [];

        globalThis.optimizely.push({
            type: 'activate',
        });
    }, [isOptimizelyEnabled, optimizelyJsKey]);
};
