import React, { MouseEventHandler, ReactElement } from 'react';
import {
    FaStarHalfAlt as StarHalfFullIcon,
    FaRegStar as StarOutlineIcon,
    FaStar as StarFullIcon,
} from 'react-icons/fa';
import { Typography, StarRating2 as StarRating } from '@vaimo-int/aem-pwa-common-components';
import type { IStarProps, IYotpoRatingProps } from './types';
// eslint-disable-next-line no-restricted-syntax
import { useYotpoContext } from '../../context/yotpoContext';
import classes from './styles/yotpoRating.module.scss';

const COUNT_STARS = 5;
const MIN_VALUE = 0.2;
const MAX_VALUE = 0.7;

const Star = ({ index, rating }: IStarProps): ReactElement => {
    if (index + MAX_VALUE < rating) return <StarFullIcon className={classes.yotpoRatingsStarFull} />;
    if (index + MIN_VALUE >= rating) return <StarOutlineIcon className={classes.yotpoRatingsStarStroke} />;

    return <StarHalfFullIcon className={classes.yotpoRatingsStar} />;
};

const YotpoRating = ({
    hasTotalCount,
    isProductPage,
    isShowEmptyStars,
    totalReviewsLabel,
    yotpoRating,
}: IYotpoRatingProps): ReactElement | null => {
    const { isEnabled } = useYotpoContext();

    if (!isEnabled || !yotpoRating) return null;

    const score = yotpoRating.average_score;

    const scrollToReview: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        globalThis.document?.getElementById('writeReview')?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    return (
        <div className={classes.yotpo}>
            {isProductPage ? (
                <div className={classes.yotpo}>
                    <StarRating
                        isShowEmptyStars={isShowEmptyStars}
                        isYotpoEnabled={isEnabled}
                        yotpoRating={yotpoRating}
                    />
                    {hasTotalCount && (
                        <Typography.Body>
                            <a href={globalThis.location.pathname + '#writeReview'} onClick={scrollToReview}>
                                {totalReviewsLabel || `(${yotpoRating.total})`}
                            </a>
                        </Typography.Body>
                    )}
                </div>
            ) : isShowEmptyStars || (score && score > 0) ? (
                <div className={classes.yotpo}>
                    <div className={classes.yotpoRatings}>
                        {[...Array(COUNT_STARS)].map((_, i) => (
                            <Star rating={score} index={i} key={`star-${i}`} />
                        ))}
                    </div>
                    {hasTotalCount && totalReviewsLabel}
                </div>
            ) : null}
        </div>
    );
};

export default YotpoRating;
