import { useEffect } from 'react';
import { toastApi } from '@/lib/context/toasts';
import { BrowserPersistence } from '@/utils';
import { useTranslations } from 'next-intl';
import { ToastEnum } from '@/lib/context/toasts/toastApi';
import { STORE_SWITCHER } from '@/constants/storage';
import { usePathname } from 'next/navigation';

export const useStoreSwitcherSuccessMessage = (): void => {
    const storage = new BrowserPersistence();
    const pathname = usePathname();
    const t = useTranslations('storeSwitcher');
    const isShowStoreToast = storage.getItem(STORE_SWITCHER.SHOULD_SHOW_STORE_TOAST) === 'true';

    useEffect(() => {
        if (!isShowStoreToast || pathname.includes('/store/switch')) {
            return;
        }

        const handleShowSuccessStoreSwitchToast = () => {
            storage.setItem(STORE_SWITCHER.SHOULD_SHOW_STORE_TOAST, 'false');
            toastApi.add({
                dismissible: true,
                message: t('successfullyChanged'),
                variant: ToastEnum.success,
            });
        };

        globalThis.window.addEventListener('load', handleShowSuccessStoreSwitchToast);

        return () => globalThis.window.removeEventListener('load', handleShowSuccessStoreSwitchToast);
    }, [isShowStoreToast, t]);
};
