import shrinkQuery from '@/lib/Apollo/utils/shrinkQuery';
import { ALL_CACHE_TAG, MAGENTO_GQL_TAG } from '@/constants/fetchTags';
import attachServerSideByPassToHeaders from '@/integrations/datadome/src/api/attachServerSideByPassToHeaders';
import isServer from '@/utils/isServer';
import processEnvironmentTags from '@/modules/cache-tags/processEnvironmentTags';

/**
 * Intercept and shrink URLs from GET queries.
 *
 * Using GET makes it possible to use edge caching in Magento Cloud, but risks
 * exceeding URL limits with default usage of Apollo's http link.
 *
 * `shrinkQuery` encodes the URL in a more efficient way.
 *
 */

const setUserAgent = (headers: Headers): void => {
    headers.set('User-Agent', 'Next.JS GraphQL Client');
};

const customFetch = async (uri: string, options: any) => {
    options.headers.accept = 'application/json';
    const resource = options.method === 'GET' ? shrinkQuery(uri) : uri;

    if (isServer()) {
        setUserAgent(options.headers);
        attachServerSideByPassToHeaders(options.headers);

        const nextTags = options.next?.tags || [];
        const nextRevalidate = options.next?.revalidate;

        const tags: Set<string> = new Set(nextTags);
        tags.add(ALL_CACHE_TAG);
        tags.add(MAGENTO_GQL_TAG);

        if (!nextRevalidate) {
            const firstData = await fetch(resource, { ...options, next: { tags: processEnvironmentTags([...tags]) } }); //here
            const magentoCacheTags = firstData.headers.get('Cache-Tags');

            if (magentoCacheTags) {
                magentoCacheTags.split(' ').forEach((item) => tags.add(item));
            }
        }

        const tagsWithEnvironmentPrefix = processEnvironmentTags([...tags]);

        if (tagsWithEnvironmentPrefix.length > 60 && process.env.NODE_ENV === 'production') {
            console.warn(`All fetch tags: ${JSON.stringify(tagsWithEnvironmentPrefix)}`);
        }

        return fetch(resource, {
            ...options,
            next: {
                // We need to clean cache storage if a query is not used frequently
                ...options.next,
                // If a revalidate is not defined, we set to 'false' (it won't expire, unless old/storage is full)
                revalidate: nextRevalidate || false,
                // NexJs has a limit of 60 tags, which it can proceed.
                // There is an assumption that sending more tags might break Vercel caching
                // (not able to read/write form cache).
                tags: tagsWithEnvironmentPrefix.slice(0, 60),
            },
        });
    } else {
        return fetch(resource, options);
    }
};

export default customFetch;
