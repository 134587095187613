'use client';

import { useIsSalesforceEnabled } from './utils/useIsSalesforceEnabled';
import {
    IGetIsSalesforceEnabled,
    ISalesforceConfig,
} from '@/integrations/salesforce-chat/src/components/SalesforceChat/types';
import { useSalesforceChat } from '@/integrations/salesforce-chat/src/components/SalesforceChat/hooks/useSalesforceChat';

interface ISalesforceChat extends ISalesforceConfig, IGetIsSalesforceEnabled {
    isEnabledStoreConfig?: boolean;
}

const SalesforceChat = ({
    customUrlsList,
    isCheckoutCartEnabled,
    isCustomUrlsEnabled,
    isEnabledStoreConfig,
    isProductPagesEnabled,
    ...rest
}: ISalesforceChat) => {
    const isEnabled = useIsSalesforceEnabled({
        customUrlsList,
        isCheckoutCartEnabled,
        isCustomUrlsEnabled,
        isGlobalEnabled: isEnabledStoreConfig,
        isProductPagesEnabled,
    });

    useSalesforceChat({ isEnabled, ...rest });

    return null;
};

export default SalesforceChat;
