import { IGetIsSalesforceEnabled, PageTypeEnum } from '../types';
import { removeStoreCodeFromUrl } from '@/utils/url';
import { usePathname, useSelectedLayoutSegments } from 'next/navigation';

const CUSTOM_URLS_DELIMITER = '\r\n';

export const useIsSalesforceEnabled = ({
    customUrlsList,
    isCheckoutCartEnabled,
    isCustomUrlsEnabled,
    isGlobalEnabled,
    isProductPagesEnabled,
}: IGetIsSalesforceEnabled): boolean => {
    const pathname = usePathname();
    const segments = useSelectedLayoutSegments();

    const hasIntersection = (array1: Array<string>, array2: Array<string>): boolean =>
        array1.some((item) => array2.includes(item));

    if (isGlobalEnabled) return true;

    if (
        isProductPagesEnabled &&
        hasIntersection([PageTypeEnum.CATEGORY_PAGE, PageTypeEnum.PRODUCT_PAGE, PageTypeEnum.SEARCH_PAGE], segments)
    )
        return true;

    if (isCheckoutCartEnabled && hasIntersection([PageTypeEnum.CART_PAGE, PageTypeEnum.CHECKOUT_PAGE], segments))
        return true;

    if (
        isCustomUrlsEnabled &&
        customUrlsList?.split(CUSTOM_URLS_DELIMITER).includes(removeStoreCodeFromUrl(pathname).substring(1))
    )
        return true;

    return false;
};
