'use client';

import { usePathname } from 'next/navigation';
import { useCartContext, useRequestContext, useUserContext } from '@/lib/context';
import { useDataDome } from '@vaimo/datadome';
import { useOneTrust } from '@vaimo-int/one-trust';
import { useFacebookCAPI } from '@vaimo/facebook-conversion-api';
import { useInitialize as useEmarsysInitialize } from '@vaimo/emarsys-tracking';
import { useGTMInitialize } from '@vaimo/google-tag-manager';
import { useMonetateInitialize } from '@vaimo/monetate';
import { useHotjar } from '@vaimo-int/hotjar';
import { useGlobalEInitialize } from '@vaimo/global-e';
import { useActivateOptimizely } from '@vaimo/optimizely';

import type { IAppIntegrationsStoreConfig } from '../types';

interface IClientSideIntegrationsProps {
    storeCode: string;
    storeConfig: IAppIntegrationsStoreConfig;
}

const ClientSideIntegrations = ({ storeCode, storeConfig }: IClientSideIntegrationsProps): null => {
    const {
        dataDomeKey,
        globalE,
        hotjarAccount,
        isDataDomeEnabled,
        isHotjarEnabled,
        isMonetateEnabled,
        isOneTrustEnabled,
        isOptimizelyEnabled,
        monetateScriptSrc,
        oneTrustKey,
        oneTrustScript,
        optimizelyJsKey,
    } = storeConfig || {};
    const pathname = usePathname();
    const [{ cartContent, cartId }] = useCartContext();
    const [{ currentUser, isSignedIn }] = useUserContext();
    const { isBot } = useRequestContext();
    const { email } = currentUser || {};

    useActivateOptimizely({ isOptimizelyEnabled, optimizelyJsKey });
    useMonetateInitialize({ isMonetateEnabled, monetateScriptSrc });

    useOneTrust({
        isDisabled: isBot || !isOneTrustEnabled,
        oneTrustKey: oneTrustKey || '',
        oneTrustScript: oneTrustScript || '',
    });

    useGlobalEInitialize({ cartContent, cartId, globalE, storeCode });

    useDataDome({ dataDomeKey, isDataDomeEnabled });

    useFacebookCAPI({ isSignedIn, pageUrl: globalThis.location?.origin + pathname });

    useEmarsysInitialize({ currentUserEmail: email });

    useGTMInitialize();

    useHotjar({ accountKey: hotjarAccount, isEnabled: isHotjarEnabled });

    return null;
};

export default ClientSideIntegrations;
