import { gql } from '@apollo/client';

export const ProductListingFragment = gql`
    fragment ProductListingFragment on Cart {
        id
        items {
            id
            uid
            product {
                id
                uid
                name
                sku
                url_key
                url_suffix
                special_price
                thumbnail {
                    url
                }
                small_image {
                    url
                }
                categories {
                    id
                    uid
                    name
                }
                price {
                    regularPrice {
                        amount {
                            currency
                            value
                        }
                    }
                }
                stock_status
                ... on ConfigurableProduct {
                    price_range {
                        minimum_price {
                            final_price {
                                currency
                                value
                            }
                            regular_price {
                                currency
                                value
                            }
                        }
                    }
                    special_price
                    variants {
                        attributes {
                            uid
                        }
                        product {
                            id
                            uid
                            small_image {
                                url
                            }
                        }
                    }
                }
                ... on ConfigurableProduct {
                    configurable_options {
                        id
                        attribute_code
                        uid
                    }
                }
            }
            prices {
                price {
                    currency
                    value
                }
                final_price {
                    currency
                    value
                }
                row_total {
                    currency
                    value
                }
                row_total_including_tax {
                    currency
                    value
                }
                total_item_discount {
                    currency
                    value
                }
                discounts {
                    amount {
                        currency
                        value
                    }
                }
            }
            quantity
            ... on ConfigurableCartItem {
                configurable_options {
                    id
                    configurable_product_option_uid
                    configurable_product_option_value_uid
                    option_label
                    value_id
                    value_label
                }
                configured_variant {
                    id
                    uid
                    sku
                }
            }
        }
    }
`;
