'use client';

import { Provider as ReduxProvider } from 'react-redux';
import ContextProviders from '@/lib/context/contextProvider';
import getClient from '@/lib/Apollo/SSR/apolloClient';
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support/ssr';
import { useRequestContext } from '@/lib/context/request/request';
import AppContextProvider from '@/lib/context/app/app';
import { FacebookContextProvider, IContextInitState } from '@vaimo/facebook-conversion-api';
import { EmarsysContextProvider } from '@vaimo/emarsys-tracking';
import { GTMContextProvider } from '@vaimo/google-tag-manager';
import { MonetateContextProvider } from '@vaimo/monetate';
import {
    IAdapterProps,
    IAppStoreConfig,
    IEmarsysConfig,
    IGTMConfig,
    IMonetateConfig,
    IBoldMetricsConfig,
    IPaypalMessageConfig,
} from '@/components/Adapter/types';
import { useEffect, useMemo } from 'react';
import { BoldMetricsContextProvider } from '@vaimo/bold-metrics';
import { YotpoContextProvider } from '@/integrations/yotpo';
import { IYotpoContext } from '@/integrations/yotpo/src/context/yotpoContext';
import PaypalMessageContextProvider from '@/integrations/paypal-message/src/context/PaypalMessage';

interface IComponentProps extends IAdapterProps {
    appStoreConfig: IAppStoreConfig;
    boldMetrics: IBoldMetricsConfig;
    emarsys: IEmarsysConfig;
    facebook: IContextInitState;
    gtm: IGTMConfig;
    monetate: IMonetateConfig;
    paypalMessage: IPaypalMessageConfig;
    yotpo: IYotpoContext;
}

const Adapter = ({
    appStoreConfig,
    boldMetrics,
    children,
    emarsys,
    facebook,
    gtm,
    monetate,
    paypalMessage,
    store,
    storeCodeInUrl,
    yotpo,
}: IComponentProps) => {
    const { magentoCacheId } = useRequestContext();

    const apolloClient = useMemo(() => {
        return getClient({ magentoCacheId, storeCode: storeCodeInUrl });
    }, []);

    useEffect(() => {
        apolloClient.persistor?.restore();

        const handleCachePersistance = () => {
            if (document.hidden) {
                apolloClient.persistor?.persist();
            } else {
                apolloClient.persistor?.restore();
            }
        };

        window.addEventListener('visibilitychange', handleCachePersistance);

        return () => {
            window.removeEventListener('visibilitychange', handleCachePersistance);
        };
    }, []);

    return (
        <ApolloNextAppProvider makeClient={() => apolloClient.client}>
            <ReduxProvider store={store}>
                <FacebookContextProvider initState={facebook}>
                    <EmarsysContextProvider initState={emarsys}>
                        <GTMContextProvider initState={gtm}>
                            <MonetateContextProvider initState={monetate}>
                                <YotpoContextProvider contextValue={yotpo}>
                                    <BoldMetricsContextProvider initState={boldMetrics}>
                                        <PaypalMessageContextProvider initState={paypalMessage}>
                                            <AppContextProvider initState={{ storeConfig: appStoreConfig }}>
                                                <ContextProviders>{children}</ContextProviders>
                                            </AppContextProvider>
                                        </PaypalMessageContextProvider>
                                    </BoldMetricsContextProvider>
                                </YotpoContextProvider>
                            </MonetateContextProvider>
                        </GTMContextProvider>
                    </EmarsysContextProvider>
                </FacebookContextProvider>
            </ReduxProvider>
        </ApolloNextAppProvider>
    );
};

export default Adapter;
