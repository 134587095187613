import { PossibleTypesMap } from '@apollo/client';

declare const POSSIBLE_TYPES: PossibleTypesMap | undefined;

export default function getPossibleTypes(): PossibleTypesMap {
    const possibleTypes = POSSIBLE_TYPES;

    if (possibleTypes === undefined) {
        console.error('possibleTypes is undefined');

        return {};
    }

    return possibleTypes;
}
