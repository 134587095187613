'use client';

import { useEffect } from 'react';
import getIsGlobalEnabled from '@vaimo/global-e/utils/getIsGlobalEnabled';
import createElement from '@vaimo/global-e/utils/createElement';
import { setCookie } from '@vaimo//global-e/utils/setCookie';
import { cookieManager } from '@vaimo-int/one-trust';

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare module globalThis {
    let GEM_Components: any;
    let document: any;
    let location: any;
}

const HOST = globalThis.location?.host;
const COOKIE_NAME = 'GlobalE_Gem_Data';

export interface IUseGlobalEInitialize {
    cartContent: any;
    cartId: string | null;
    globalE: {
        css_src: string | null;
        is_enabled: boolean;
        js_src: string | null;
    };
    storeCode: string;
}

export const useGlobalEInitialize = ({ cartContent, cartId, globalE, storeCode }: IUseGlobalEInitialize) => {
    const { css_src, is_enabled, js_src } = globalE;
    const isGlobalEnabled = getIsGlobalEnabled(storeCode, is_enabled);

    useEffect(() => {
        try {
            if (typeof globalThis.GEM_Components === 'object') {
                globalThis.GEM_Components.ExternalMethodsComponent.ReloadCart();
            }
        } catch (e) {
            console.error(e);
        }
    }, [cartContent?.cart?.items]);

    useEffect(() => {
        if (!isGlobalEnabled || !cartId || !css_src || !js_src) {
            return;
        }

        const cookieValue = {
            CartId: cartId,
            PreferedCulture: storeCode,
            StoreCode: storeCode,
            StoreInstanceCode: HOST,
        };

        setCookie({
            name: COOKIE_NAME,
            type: cookieManager.PrivacyGroupEnum.NECESSARY,
            value: cookieValue,
        });

        const link = createElement('link', {
            href: css_src,
            rel: 'stylesheet',
            type: 'text/css',
        });
        const script = createElement('script', {
            id: 'globaleScript',
            innerHTML: `var geStoreCode="${storeCode}";var geStoreCodeInstance="${HOST}";`,
        });
        const scriptSource = createElement('script', { async: 'true', src: js_src, type: 'text/javascript' });

        globalThis.document.head.appendChild(link);
        globalThis.document.body.appendChild(script);
        globalThis.document.body.appendChild(scriptSource);

        return () => {
            globalThis.document.head.removeChild(link);
            globalThis.document.body.removeChild(script);
            globalThis.document.body.removeChild(scriptSource);
        };
    }, [cartId, storeCode, isGlobalEnabled]);
};
