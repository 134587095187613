import { useEffect } from 'react';
import { executeRawScript } from '@vaimo/datadome/src/rawScript';

export const useDataDome = ({ dataDomeKey, isDataDomeEnabled }) => {
    useEffect(() => {
        if (!isDataDomeEnabled) return;

        // Datadome is white-listed for user consent
        executeRawScript(dataDomeKey);
    }, [isDataDomeEnabled, dataDomeKey]);
};
