import React from 'react';
import { FiX as CrossIcon } from 'react-icons/fi';
import Button from '@/components/Button';
import Icon from '@/components/Notification/icon';
import defaultClasses from './toast.module.scss';

export interface IToast {
    actionText?: string;
    dismissible?: boolean;
    handleAction?: () => void;
    handleDismiss?: () => void;
    icon?: object;
    id: number;
    message: string;
    onAction?: () => void;
    onDismiss?: () => void;
    variant?: 'default' | 'success' | 'error';
}

const Toast = (props: IToast) => {
    const {
        actionText,
        dismissible,
        handleAction,
        handleDismiss,
        message,
        onAction,
        onDismiss,
        variant = 'default',
    } = props;

    const controls =
        onDismiss || dismissible ? (
            <button className={defaultClasses.dismissButton} onClick={handleDismiss}>
                <CrossIcon />
            </button>
        ) : null;

    const actions = onAction ? (
        <div className={defaultClasses.actions}>
            <Button className={defaultClasses.actionButton} onClick={handleAction} isSmall variant={'trigger'}>
                {actionText}
            </Button>
        </div>
    ) : null;

    return (
        <div className={defaultClasses[`${variant}Toast`]}>
            <Icon variant={variant} className={defaultClasses.icon} />
            <div className={defaultClasses.message}>{message}</div>
            <div className={defaultClasses.controls}>{controls}</div>
            {actions}
        </div>
    );
};

export default React.memo(Toast);
