import React, { createContext, ReactNode, useContext } from 'react';
import { PaypalMessageFields } from '@/components/AppIntegrations/types';

interface PaypalMessageContext extends PaypalMessageFields {}

const PaypalMessageContext = createContext({} as PaypalMessageContext);

const PaypalMessageContextProvider = ({
    children,
    initState,
}: {
    children: ReactNode;
    initState: PaypalMessageContext;
}) => {
    return <PaypalMessageContext.Provider value={initState}>{children}</PaypalMessageContext.Provider>;
};

export default PaypalMessageContextProvider;

export const usePaypalMessageContext = () => useContext(PaypalMessageContext);
