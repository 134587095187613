import IStore from '@/modules/adobe-commerce-webpack/types/store';

declare const AVAILABLE_STORES: IStore[] | undefined;

export default function getAvailableStores(): IStore[] {
    const availableStores = AVAILABLE_STORES;

    if (!Array.isArray(availableStores)) {
        console.error('AVAILABLE_STORES is not an array:', availableStores);

        return [];
    }

    return availableStores;
}
