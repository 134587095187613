import { setContext } from '@apollo/client/link/context';
import { DefaultContext } from '@apollo/client/core';
import store from '@/lib/store';

const isServer = !globalThis.document;

const authLink = () =>
    setContext((_, { headers }): DefaultContext => {
        // return the headers to the context so httpLink can read them
        if (isServer) return { headers };

        const token = store.getState().user?.token;

        if (!token) return { headers };

        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });

export default authLink;
