import type { UseInitSalesforceChatOutput } from '../types';
import { ISalesforceConfig } from '../types';

export const useInitSalesforceChat = ({
    baseCoreUrl,
    communityEndpointUrl,
    eswConfigDevName,
    gslbBaseUrl,
    locale,
    orgId,
    settings,
}: ISalesforceConfig): UseInitSalesforceChatOutput => {
    const getSalesforceChatScript = () =>
        Object.assign(document.createElement('script'), {
            defer: true,
            src: `${baseCoreUrl}/embeddedservice/5.0/esw.min.js`,
        });

    const initESW = () => {
        window.embedded_svc.settings.displayHelpButton = true;
        window.embedded_svc.settings.language = locale?.split('_')[0] || '';
        window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        window.embedded_svc.settings.entryFeature = 'LiveAgent';
        window.embedded_svc.settings.extraPrechatFormDetails = [
            {
                displayToAgent: true,
                label: 'URL',
                transcriptFields: ['Visitor_url__c'],
                value: window.location.href,
            },
        ];
        window.embedded_svc.init(
            baseCoreUrl || '',
            communityEndpointUrl || '',
            gslbBaseUrl || '',
            orgId || '',
            eswConfigDevName || '',
            JSON.parse(settings || '{}') || {},
        );
    };

    return {
        getSalesforceChatScript,
        initESW,
    };
};
