'use client';

import React, { ReactElement } from 'react';
import { useStoreSwitcher } from './hooks/useStoreSwitcher';
import { useStoreSwitcherSuccessMessage } from './hooks/useStoreSwitcherSuccessMessage';
import StoreSwitcherDialog from './modules/StoreSwitcherDialog';
import { StoreSwitcherProvider } from './context/storeSwitcherContext';

const StoreSwitcherComponent = (): ReactElement | null => {
    const { handleGeolocationPopupCancel, setDefaultSwitcherVariant, shouldHideSwitcher, switcherVariant } =
        useStoreSwitcher();

    useStoreSwitcherSuccessMessage();

    if (shouldHideSwitcher) {
        return null;
    }

    return (
        <StoreSwitcherDialog
            handleGeolocationPopupCancel={handleGeolocationPopupCancel}
            setDefaultSwitcherVariant={setDefaultSwitcherVariant}
            switcherVariant={switcherVariant}
        />
    );
};

const StoreSwitcher = () => (
    <StoreSwitcherProvider>
        <StoreSwitcherComponent />
    </StoreSwitcherProvider>
);

export default StoreSwitcher;
