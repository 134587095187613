/* eslint-disable */
export const executeRawScript = (dataDomeKey) => {
    try {
        !(function (a, b, c, d, e, f) {
            a.ddjskey = e;
            a.ddoptions = f || null;
            var m = b.createElement(c),
                n = b.getElementsByTagName(c)[0];
            (m.async = 1), (m.src = d), n.parentNode.insertBefore(m, n);
        })(window, document, 'script', 'https://js.datadome.co/tags.js', dataDomeKey, {
            ajaxListenerPath: true,
            overrideAbortFetch: true,
        });
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(e);
        }
    }
};
