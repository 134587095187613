'use client';

import React, { ReactElement } from 'react';
import useYotpoScripts from './hooks/useYotpoScripts';
import type { IYotpoReviewWidgetProps } from './types';

const YotpoReviewWidget = ({
    imageUrl,
    priceCurrency,
    priceValue,
    productId,
    productUrl,
    title,
}: IYotpoReviewWidgetProps): ReactElement => {
    useYotpoScripts();

    return (
        <div
            className="yotpo yotpo-main-widget"
            data-product-id={productId}
            data-name={title}
            data-url={productUrl}
            data-image-url={imageUrl}
            data-currency={priceCurrency}
            data-price={priceValue}
            id="yotpoReviewsWidget"
        />
    );
};

export default YotpoReviewWidget;
