'use client';

import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import localforage from 'localforage';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { NextSSRInMemoryCache, NextSSRApolloClient } from '@apollo/experimental-nextjs-app-support/ssr';
import { RetryLink } from '@apollo/client/link/retry';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import getWithPath from 'lodash.get';
import setWithPath from 'lodash.set';
import MutationQueueLink from '@adobe/apollo-link-mutation-queue';
import { CACHE_PERSIST_PREFIX, GQL_ENDPOINT } from '../constants';
import { toastApi } from '@/lib/context/toasts';
import customFetch from '@/lib/Apollo/utils/customFetch';
import authLink from '@/lib/Apollo/links/authLink';
import { MAGENTO_CACHE_ID_COOKIE_KEY, MAGENTO_CACHE_ID_HEADER } from '../constants';
import { setCookie } from '@/utils/cookieManager';
import getPossibleTypes from '@/modules/adobe-commerce-webpack/getPossibleTypes';
import magentoGqlCacheSetterLink from '@/lib/Apollo/links/magentoGqlCacheSetterLink';
import typePolicies from '@/lib/Apollo/typePolicies';
import getAvailableStores from '@/modules/adobe-commerce-webpack/getAvailableStores';
import { cookieManager } from '@vaimo-int/one-trust';
import { clearToken } from '@/lib/store/actions/user';
import { removeCart } from '@/lib/store/actions/cart';
import store from '@/lib/store';
import { ACTIVE_CART_ERROR_MESSAGE, MINICART_ERROR_MESSAGE } from '@/components/MiniCart/hooks/useUpdateMiniCartData';

const isServer = !globalThis.document;
const SKIP_TOAST_QUERIES_LIST = ['setShippingAddressesOnCart'];
const SKIP_TOAST_MESSAGES_LIST = [ACTIVE_CART_ERROR_MESSAGE, MINICART_ERROR_MESSAGE];

const getClient = ({ magentoCacheId, storeCode }) => {
    const apiBase = new URL(
        GQL_ENDPOINT,
        isServer ? process.env.MAGENTO_BACKEND_URL : globalThis.location.origin,
    ).toString();

    const trySessionBasedAuthenticationOnAuthorizationError = onError(({ forward, graphQLErrors, operation }) => {
        if (isServer || !graphQLErrors?.some((err) => err.extensions.category === 'graphql-authorization')) {
            return;
        }

        clearToken()(store.dispatch);
        removeCart()(store.dispatch);

        if (globalThis?.location) {
            globalThis.location.reload();
        }

        return forward(operation);
    });

    const errorLink = onError((handler) => {
        const { graphQLErrors, networkError, operation, response } = handler;

        // Skipping toast messages, in case if we want to handle it differently
        const skipErrorToasts = operation?.getContext()?.skipErrorToasts;

        if (graphQLErrors) {
            graphQLErrors.forEach(({ locations, message, path }) => {
                if (SKIP_TOAST_QUERIES_LIST.includes(path?.[0]) || SKIP_TOAST_MESSAGES_LIST.includes(message)) return;

                !(skipErrorToasts || isServer) &&
                    toastApi.add({
                        message: message.toString(),
                        variant: 'error',
                    });

                console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
            });
        }

        if (networkError) {
            !(skipErrorToasts || isServer) &&
                toastApi.add({
                    message: networkError.toString(),
                    variant: 'error',
                });
            console.error(`[Network error]: ${networkError}`);
        }

        if (response) {
            const { data, errors } = response;
            let pathToCartItems;

            // It's within the GraphQL spec to receive data and errors, where
            // errors are merely informational and not intended to block. Almost
            // all existing components were not built with this in mind, so we
            // build special handling of this error message so we can deal with
            // it at the time we deem appropriate.
            errors.forEach(({ message, path }, index) => {
                if (
                    message === 'Some of the products are out of stock.' ||
                    message === 'There are no source items with the in stock status'
                ) {
                    if (!pathToCartItems) {
                        pathToCartItems = path?.slice(0, -1);
                    }

                    // Set the error to null to be cleaned up later
                    response.errors[index] = null;
                }
            });

            // indicator that we have some cleanup to perform on the response
            if (pathToCartItems) {
                const cartItems = getWithPath(data, pathToCartItems);
                const filteredCartItems = cartItems.filter((cartItem) => cartItem !== null);
                setWithPath(data, pathToCartItems, filteredCartItems);

                const filteredErrors = response.errors.filter((error) => error !== null);
                // If all errors were stock related and set to null, reset the error response so it doesn't throw
                response.errors = filteredErrors.length ? filteredErrors : undefined;
            }
        }
    });

    // In order to successfully upload files, we have to use createUploadLink instead of createHttpLink
    // The base code above is left as commented out code to see what has changed and may make it easier to debug in the future.
    const httpLink = createUploadLink({
        credentials: 'same-origin',
        fetch: customFetch,
        uri: apiBase,
        useGETForQueries: true,
    });

    const mutationQueueLink = new MutationQueueLink();

    const retryLink = new RetryLink({
        attempts: {
            max: 5,
            retryIf: (error) => error && !isServer && navigator?.onLine,
        },
        delay: {
            initial: 300,
            jitter: true,
            max: Infinity,
        },
    });

    const storeLink = setContext((_, { headers }) => {
        const storeCurrency = getAvailableStores().find(
            ({ code }) => code === storeCode,
        )?.default_display_currency_code;

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                store: storeCode,
                ...(storeCurrency && {
                    'Content-Currency': storeCurrency,
                }),
            },
        };
    });

    const magentoGqlCacheGetterLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
            // Extract MagentoCacheId header value, to set it later in cookie on a client
            const { response } = operation.getContext();
            const responseCacheId = response.headers.get(MAGENTO_CACHE_ID_HEADER);

            if (responseCacheId) {
                setCookie(MAGENTO_CACHE_ID_COOKIE_KEY, responseCacheId, cookieManager.PrivacyGroupEnum.NECESSARY);
            }

            return data;
        });
    });

    const apolloLink = ApolloLink.from([
        // preserve this array order, it's important
        // as the terminating link, `httpLink` must be last
        mutationQueueLink,
        retryLink,
        trySessionBasedAuthenticationOnAuthorizationError,
        authLink(),
        magentoGqlCacheSetterLink(magentoCacheId),
        magentoGqlCacheGetterLink,
        storeLink,
        errorLink,
        httpLink,
    ]);

    const cache = new NextSSRInMemoryCache({
        possibleTypes: getPossibleTypes(),
        typePolicies,
    });

    const client = new NextSSRApolloClient({
        cache,
        connectToDevTools: process.env.NODE_ENV === 'development',
        link: apolloLink,
        ssrMode: true,
    });

    const persistor = isServer
        ? undefined
        : new CachePersistor({
              cache,
              debug: process.env.NODE_ENV === 'development',
              key: `${CACHE_PERSIST_PREFIX}-${storeCode}`,
              storage: new LocalForageWrapper(localforage),
          });

    return { client, persistor };
};

export default getClient;
