import { useAppContext } from '@/lib/context';
import { STORE_SWITCHER_LOCATION, STORE_SWITCHER_VARIANT } from '@/constants/storeSwitcher';
import type { IUseStoreSwitcherDialog, IUseStoreSwitcherDialogProps } from '@/components/StoreSwitcher/types';

/**
 * The useStoreSwitcherDialog talon complements the StoreSwitcherDialog component.
 *
 * @kind function
 *
 * @param {object}      props
 * @param {String}      props.switcherVariant - Current store switcher view.
 *
 * @returns {Object}    talonProps
 * @returns {String}    talonProps.drawer - Current drawer view.
 * @returns {String}    talonProps.googleAnalyticsPopupSource - From where the popup was initiated - needed for GA.
 * @returns {Boolean}   talonProps.isStoreSwitcherGeolocationVariant - Whether the store switcher variant is geolocation.
 * @returns {Boolean}   talonProps.isStoreSwitcherLocationBottom - Whether the store switcher located in the bottom.
 * @returns {Boolean}   talonProps.isStoreSwitcherLocationCenter - Whether the store switcher located in the center.
 * @returns {Boolean}   talonProps.isStoreSwitcherLocationTop - Whether the store switcher located in the top.
 * @returns {Boolean}   talonProps.isStoreSwitcherOpen - Whether the store switcher is open.
 * @returns {String}    talonProps.storeGroupName - The store group name.
 * @returns {String}    talonProps.storeName - The store name.
 */

const GOOGLE_ANALYTICS_POPUP_SOURCE = {
    [STORE_SWITCHER_LOCATION.DEFAULT]: 'onload',
    [STORE_SWITCHER_LOCATION.BOTTOM_RIGHT]: 'footer',
    [STORE_SWITCHER_LOCATION.CENTER]: 'checkout',
    [STORE_SWITCHER_LOCATION.TOP_RIGHT]: 'header',
};

export const useStoreSwitcherDialog = ({ switcherVariant }: IUseStoreSwitcherDialogProps): IUseStoreSwitcherDialog => {
    const [{ drawer, isStoreSwitcherOpen, storeConfig, storeSwitcherLocation }] = useAppContext();
    const { storeGroupName, storeName } = storeConfig;

    const isStoreSwitcherGeolocationVariant = switcherVariant === STORE_SWITCHER_VARIANT.GEOLOCATION;
    const isStoreSwitcherLocationBottom =
        storeSwitcherLocation === STORE_SWITCHER_LOCATION.BOTTOM_RIGHT ||
        storeSwitcherLocation === STORE_SWITCHER_LOCATION.DEFAULT;
    const isStoreSwitcherLocationCenter = storeSwitcherLocation === STORE_SWITCHER_LOCATION.CENTER;
    const isStoreSwitcherLocationTop = storeSwitcherLocation === STORE_SWITCHER_LOCATION.TOP_RIGHT;
    const googleAnalyticsPopupSource = GOOGLE_ANALYTICS_POPUP_SOURCE[storeSwitcherLocation];

    return {
        drawer,
        googleAnalyticsPopupSource,
        isStoreSwitcherGeolocationVariant,
        isStoreSwitcherLocationBottom,
        isStoreSwitcherLocationCenter,
        isStoreSwitcherLocationTop,
        isStoreSwitcherOpen,
        storeGroupName,
        storeName,
    };
};
