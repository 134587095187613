'use client';

import React, { ReactElement } from 'react';
import { FiX as CrossIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import Portal from '@/components/Portal';
import Mask from '@/components/Mask';
import Button from '@/components/Button';
import StoreSwitcherForm from '../StoreSwitcherForm';
import { isCookieSet } from '@/utils/cookieManager';
import { useStoreSwitcherDialog } from './hooks/useStoreSwitcherDialog';
import { GEOLOCATION_STORE_COOKIE } from '@/constants/cookies';
import clsx from 'clsx';
import classes from './styles/storeSwitcherDialog.module.scss';

interface IStoreSwitcherDialogProps {
    handleGeolocationPopupCancel: () => void;
    setDefaultSwitcherVariant: (value: string) => void;
    switcherVariant: string;
}

const StoreSwitcherDialog = ({
    handleGeolocationPopupCancel,
    setDefaultSwitcherVariant,
    switcherVariant,
}: IStoreSwitcherDialogProps): ReactElement | null => {
    const tGlobal = useTranslations('global');
    const tStoreSwitcher = useTranslations('storeSwitcher');
    const {
        drawer,
        googleAnalyticsPopupSource,
        isStoreSwitcherGeolocationVariant,
        isStoreSwitcherLocationBottom,
        isStoreSwitcherLocationCenter,
        isStoreSwitcherLocationTop,
        isStoreSwitcherOpen,
        storeGroupName,
        storeName,
    } = useStoreSwitcherDialog({ switcherVariant });

    if (!isStoreSwitcherOpen) {
        return null;
    }

    const switchCountryButtonLabelLocalized = tStoreSwitcher('switchCountry');

    const stayButtonLabelLocalized = tStoreSwitcher('iWantToStay');

    const dialogContent = isStoreSwitcherGeolocationVariant ? (
        <>
            <h3 className={classes.heading}>
                {tStoreSwitcher('currentVersion', { country: storeGroupName, language: storeName })}
            </h3>
            <Button
                aria-label={switchCountryButtonLabelLocalized}
                data-gtm-btn-label={'Switch country'}
                onClick={setDefaultSwitcherVariant}
                role="button"
                type="button"
                variant={'tertiary'}
            >
                {switchCountryButtonLabelLocalized}
            </Button>
            <Button
                aria-label={stayButtonLabelLocalized}
                data-gtm-btn-label={'I want to stay'}
                onClick={handleGeolocationPopupCancel}
                role="button"
                type="button"
                variant={'primary'}
            >
                {stayButtonLabelLocalized}
            </Button>
        </>
    ) : (
        <>
            <h3 className={classes.heading}>
                {isCookieSet(GEOLOCATION_STORE_COOKIE) ? (
                    <>{tStoreSwitcher('selectCountry')}</>
                ) : (
                    <>{tStoreSwitcher('confirmLocation')}</>
                )}
            </h3>
            <StoreSwitcherForm />
        </>
    );

    const isStoreSwitcherModal = isStoreSwitcherOpen && drawer !== 'nav';

    const rootClass = clsx(
        classes.root,
        isStoreSwitcherLocationBottom && classes.positionBottomRight,
        isStoreSwitcherLocationCenter && classes.positionCenter,
        isStoreSwitcherLocationTop && classes.positionTopRight,
        isStoreSwitcherModal && classes.rootVisible,
    );

    return (
        <Portal>
            {isStoreSwitcherModal && <Mask isActive={isStoreSwitcherModal} dismiss={handleGeolocationPopupCancel} />}
            <aside data-pop-up-source={googleAnalyticsPopupSource} className={rootClass}>
                <Button
                    aria-label={tGlobal('close')}
                    className={classes.closeButton}
                    onClick={handleGeolocationPopupCancel}
                    role="button"
                    tabIndex={0}
                    type="button"
                    variant={'trigger'}
                >
                    <CrossIcon />
                </Button>
                {dialogContent}
            </aside>
        </Portal>
    );
};

StoreSwitcherDialog.displayName = 'StoreSwitcherDialog';

export default StoreSwitcherDialog;
