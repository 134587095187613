import { useEffect } from 'react';
import { executeOptimizelyScript } from '../helpers/optimizelyScript';

interface IOptimizelyProps {
    isOptimizelyEnabled: boolean;
    optimizelyJsKey?: string;
}

export const useOptimizelyInitialize = ({ isOptimizelyEnabled, optimizelyJsKey }: IOptimizelyProps) => {
    useEffect(() => {
        if (!isOptimizelyEnabled || !optimizelyJsKey) return;

        executeOptimizelyScript(optimizelyJsKey);
    }, [isOptimizelyEnabled, optimizelyJsKey]);
};
