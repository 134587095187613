import { gql } from '@apollo/client';

import { ProductListingFragment } from '@/talons/ProductListing/productListingFragments.gql';
import { PriceSummaryFragment } from '@/talons/PriceSummary/priceSummaryFragments.gql';
import { AppliedCouponsFragment } from '@/components/PriceAdjustments/CouponCode/api/couponCodeFragments.gql';
import { AppliedStoreCreditFragment } from '@/components/AccountStoreCredit/api/appliedStoreCreditFragment.gql';

export const CartPageFragment = gql`
    fragment CartPageFragment on Cart {
        id
        total_quantity
        ...AppliedCouponsFragment
        ...ProductListingFragment
        ...PriceSummaryFragment
        ...AppliedStoreCreditFragment
    }
    ${AppliedCouponsFragment}
    ${ProductListingFragment}
    ${PriceSummaryFragment}
    ${AppliedStoreCreditFragment}
`;
