const getEnvironmentPrefix = (): string => {
    const environment = process.env.VERCEL_ENV || 'development';

    return environment.slice(0, 4) + '-';
};

export default function processEnvironmentTags(tags: string[]): string[] {
    const prefix = getEnvironmentPrefix();

    return tags.filter(Boolean).map((tag) => (tag.startsWith(prefix) ? tag : `${prefix}${tag}`));
}
