import { useEffect, useMemo, useState } from 'react';

import type {
    ISavedRatings,
    ISavedReview,
    IUseYotpo,
    IUseYotpoProps,
    IYotpoBottomline,
    IYotpoRating,
    ProductId,
} from '../types';
// eslint-disable-next-line no-restricted-syntax
import { useYotpoContext } from '../../../context/yotpoContext';

/**
 * Return props necessary to render a YotpoRating component.
 *
 * @param {Object}   props
 * @param {Array}    props.productIds - productIds
 * @param {Number}   props.currentProductId - productId
 */

export const useYotpo = ({ currentProductId, productIds }: IUseYotpoProps): IUseYotpo => {
    const { appKey, isEnabled } = useYotpoContext();
    const [ratings, setRatings] = useState<ISavedRatings>({});
    const [reviews, setReviews] = useState<ISavedReview[]>([]);
    const [bottomlineData, setBottomlineData] = useState<IYotpoBottomline | null>(null);

    useEffect(() => {
        if (
            !appKey ||
            !Array.isArray(productIds) ||
            !productIds.length ||
            !isEnabled ||
            !productIds.filter((pid) => !ratings[pid]).length
        ) {
            return;
        }

        fetch('https://staticw2.yotpo.com/batch', {
            body: JSON.stringify({
                app_key: appKey,
                methods: productIds.map((pid) => ({
                    format: 'json',
                    method: 'bottomline',
                    params: { pid: String(pid) },
                })),
            }),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((data) => {
                const mappedData: ISavedRatings = data.reduce(
                    (collection: ISavedRatings, { result }: { result: IYotpoRating }, index: number) => {
                        collection[productIds[index]] = result;

                        return collection;
                    },
                    {},
                );

                setRatings((prevRatings) => {
                    return { ...prevRatings, ...mappedData };
                });
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.warn(error);
                }
            });
    }, [productIds, ratings]);

    // fetching reviews list by productId
    useEffect(() => {
        if (!currentProductId || !isEnabled || !appKey) {
            return;
        }

        fetch(`https://api-cdn.yotpo.com/v1/widget/${appKey}/products/${currentProductId}/reviews.json`, {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Error! status: ${response.status}`);
                }

                return response.json();
            })
            .then((data: { response: { bottomline: IYotpoBottomline; reviews: ISavedReview[] } }) => {
                const { response } = data || {};
                setBottomlineData(response?.bottomline || null);
                setReviews(response?.reviews || []);
            })
            .catch((error) => {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(error);
                }
            });
    }, [currentProductId]);

    const getYotpoRating = (productId: ProductId): IYotpoRating | Record<string, never> =>
        ratings ? ratings[productId] : {};

    const fitSizeInfo = useMemo(() => {
        if (!bottomlineData?.custom_fields_bottomline) {
            return null;
        }

        for (const childObject of Object.values(bottomlineData.custom_fields_bottomline)) {
            if (childObject?.type === 'Size') {
                return childObject.average_score;
            }
        }

        return null;
    }, [bottomlineData]);

    return {
        fitSizeInfo,
        getYotpoRating,
        reviews,
    };
};
