'use client';

import { useEffect, useMemo } from 'react';
import { BrowserPersistence } from '@//utils';

const storage = new BrowserPersistence();

/**
 * This component checks for use of a store code in the url that is not the
 * current base. If found, it updates the local storage values for code/currency
 * and reloads the page so that they are used in the graphQL headers.
 *
 * Only for backward compatibility with Venia
 */
const StoreCodeRoute = ({ storeCode, storeCurrency }) => {
    const storeSecureBaseMediaUrl = process.env.MAGENTO_BACKEND_URL + 'media';
    const currentStoreCode = storage.getItem('store_view_code');

    // If useAdapter determined the storeCode, but it is not the one we have in local storage,
    // we update the values in local storage to match the new store code.
    useEffect(() => {
        if (storeCode === currentStoreCode) {
            return;
        }

        storage.setItem('store_view_code', storeCode);
        storage.setItem('store_view_currency', storeCurrency);
        storage.setItem('store_view_secure_base_media_url', storeSecureBaseMediaUrl);
    }, [currentStoreCode, storeCode, storeCurrency]);

    return null;
};

export default StoreCodeRoute;
