import { cookieManager } from '@vaimo-int/one-trust';

const toString = (value: any) => (typeof value === 'string' ? value : JSON.stringify(value));

export const setCookie = ({
    name,
    timeToLiveInMinutes = 60,
    type,
    value,
}: {
    name: string;
    timeToLiveInMinutes?: number;
    type: cookieManager.PrivacyGroupEnum;
    value: any;
}) => {
    if (!document || typeof document.cookie !== 'string') return false;

    const ttl = timeToLiveInMinutes * 60 * 1000;
    const valueEncodedString = encodeURIComponent(toString(value));
    const date = new Date();
    date.setTime(date.getTime() + ttl);

    cookieManager.executeIfAllowed(() => {
        document.cookie = `${name}=${valueEncodedString};path=/;expires=${date.toUTCString()}`;
    }, type);

    return true;
};
