import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let optimizely: any;
}

export const useTrackRevenue = (orderValue: number | null) => {
    useEffect(() => {
        if (!globalThis.optimizely) {
            globalThis.optimizely = [];
        }

        if (orderValue) {
            globalThis.optimizely.push({
                eventName: 'trackRevenue',
                tags: {
                    revenue: orderValue,
                },
                type: 'event',
            });
        }
    }, [orderValue]);
};
