'use client';

import { usePathname } from 'next/navigation';
import getAvailableStores from '@/modules/adobe-commerce-webpack/getAvailableStores';

const useStoreCodeInUrl = (): string | undefined => {
    const pathname = usePathname();
    const regex = /^[a-z]{2}_([a-z]{2}|global)(_ww)?$/i;
    const [, pathStoreCode] = pathname.split('/');
    const isStoreCodeInUrl = pathStoreCode?.match(regex)?.input;

    if (!isStoreCodeInUrl) {
        return;
    }

    const availableStore = getAvailableStores().find(({ code }) => code === pathStoreCode.toLowerCase());

    return availableStore?.code || process.env.STORE_VIEW_CODE;
};

export default useStoreCodeInUrl;
