const USER_AGENT_HEADER: string = 'User-Agent';
const USER_AGENT_SERVER_SIDE: string = 'Vaimo DataDome Integration';

const setUserAgentWithDataDomeByPass = (headers: Headers): void => {
    let userAgent = headers.get(USER_AGENT_HEADER) ?? USER_AGENT_SERVER_SIDE;

    const dataDomeByPassKey = process.env.DATADOME_SERVERSIDE_ONLY_BYPASS;
    if (dataDomeByPassKey) {
        userAgent = `${userAgent} ${dataDomeByPassKey}`;
    }

    headers.set(USER_AGENT_HEADER, userAgent);
};

export default setUserAgentWithDataDomeByPass;
