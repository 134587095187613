/**
 * Tags to validate Next.js Cache
 */

const HOUR_IN_SECONDS = 60 * 60;

export const ALL_CACHE_TAG = 'ALL_CACHE';
export const MAGENTO_GQL_TAG = 'MAGENTO_GQL';
export const AEM_CONTENT_TAG = 'AEM_CONTENT';
export const MEGA_MENU_TTL = HOUR_IN_SECONDS;
export const CMS_BLOCK_TTL = HOUR_IN_SECONDS;

export const CMS_PAGE_TTL = HOUR_IN_SECONDS;

export const CONFIG_TTL = HOUR_IN_SECONDS;

export const CATEGORY_TTL = HOUR_IN_SECONDS;
export const PRODUCT_TTL = HOUR_IN_SECONDS;

export const URL_RESOLVER_TTL = HOUR_IN_SECONDS;
