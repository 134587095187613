export enum PageTypeEnum {
    CART_PAGE = 'cart',
    CHECKOUT_PAGE = 'checkout',
    SEARCH_PAGE = 'catalogsearch',
    CATEGORY_PAGE = 'plp',
    PRODUCT_PAGE = 'pdp',
}

export type UseInitSalesforceChatOutput = {
    getSalesforceChatScript: () => HTMLScriptElement;
    initESW: () => void;
};

export interface ISalesforceConfig {
    baseCoreUrl?: string | null;
    communityEndpointUrl?: string | null;
    customUrlsList?: string | null;
    eswConfigDevName?: string | null;
    gslbBaseUrl?: string | null;
    locale?: string;
    orgId?: string | null;
    settings?: string | null;
}

export interface IGetIsSalesforceEnabled {
    customUrlsList?: string | null;
    isCheckoutCartEnabled?: boolean;
    isCustomUrlsEnabled?: boolean;
    isGlobalEnabled?: boolean;
    isProductPagesEnabled?: boolean;
}
