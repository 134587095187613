import { useEffect } from 'react';
import { cookieManager } from '@vaimo-int/one-trust';
import debounce from 'lodash.debounce';
import { useYotpoContext } from '@/integrations/yotpo/src/context/yotpoContext';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let yotpo: any;
}

const useYotpoScripts = (): void => {
    const { appKey } = useYotpoContext();

    const debouncedRefresh = debounce(() => {
        globalThis.yotpo?.refreshWidgets.bind(globalThis.yotpo)();
    }, 1000);

    useEffect(() => {
        if (!appKey) return;

        if (globalThis.yotpo) {
            debouncedRefresh();
        } else {
            cookieManager.insertScript({
                async: true,
                groupId: cookieManager.PrivacyGroupEnum.FUNCTIONAL,
                url: `https://staticw2.yotpo.com/${appKey}/widget.js`,
            });
        }
    }, [appKey]);
};

export default useYotpoScripts;
