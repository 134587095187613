'use client';

import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { useAppContext } from '@/lib/context';
import classes from '../demoNotice.module.scss';

const DemoNoticeWrapper = ({ children }: { children: ReactNode }): ReactElement => {
    const [{ isSearchOpen }] = useAppContext();

    return (
        <div
            className={clsx(classes.demoNotice, isSearchOpen && classes.demoNoticeOpenSearch)}
            data-mt-type="demo-notice"
        >
            {children}
        </div>
    );
};

export default DemoNoticeWrapper;
