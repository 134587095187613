'use client';

import React, { createContext, ReactNode, useContext } from 'react';

export interface IYotpoContext {
    appKey?: string | null;
    isEnabled: boolean;
}

const YotpoContext = createContext({} as IYotpoContext);

const YotpoContextProvider = ({ children, contextValue }: { children: ReactNode; contextValue: IYotpoContext }) => {
    return <YotpoContext.Provider value={contextValue}>{children}</YotpoContext.Provider>;
};

export default YotpoContextProvider;

export const useYotpoContext = () => useContext(YotpoContext);
